require('particles.js');
import * as bs from 'bootstrap';

function drawBrandText() {
    let text = `
     /$$                                                   /$$
    | $$                                                  | $$
   /$$$$$$    /$$$$$$   /$$$$$$   /$$$$$$$  /$$$$$$   /$$$$$$$  /$$$$$$
  |_  $$_/   /$$__  $$ |____  $$ /$$_____/ /$$__  $$ /$$__  $$ /$$__  $$
    | $$    | $$$$$$$$  /$$$$$$$| $$      | $$  \\ $$| $$  | $$| $$$$$$$$
    | $$ /$$| $$_____/ /$$__  $$| $$      | $$  | $$| $$  | $$| $$_____/
    |  $$$$/|  $$$$$$$|  $$$$$$$|  $$$$$$$|  $$$$$$/|  $$$$$$$|  $$$$$$$
     \\___/   \\_______/ \\_______/ \\_______/ \\______/  \\_______/ \\_______/
    `;
    console.log(text);
}

function initParticlesJS() {
    if ($('#particles-js').length) {
        particlesJS.load('particles-js', '/plugins/particles/particles.min.json');
    }
}

function setCookie(name, value) {
    var d = new Date();
    d.setTime(d.getTime() + (365*24*60*60*1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function toggleDarkMode(_body, isActive) {
    let path = '/assets/img/teacode';
    if (isActive) {
        _body.addClass('dark-mode').removeClass('light-mode');
        setCookie('mode', 'dark');
        $('img.logo').attr('src', `${path}/logo-dark-mode.png`);
        $('#icon').attr('href', `${path}/logo-dark-mode.png`);
        $('#apple-touch-icon').attr('href', `${path}/logo-dark-mode.png`);
    } else {
        _body.removeClass('dark-mode').addClass('light-mode');
        setCookie('mode', 'light');
        $('img.logo').attr('src', `${path}/logo-light-mode.png`);
        $('#icon').attr('href', `${path}/logo-light-mode.png`);
        $('#apple-touch-icon').attr('href', `${path}/logo-light-mode.png`);
    }
}

function initDarkMode() {
    let _body = $(document.body);
    $(document).on('click', '.toggle-dark-mode', function () {
        let _this = $(this);
        let _isActive = !_body.hasClass('dark-mode');
        // let _isActive = localStorage.getItem('isDarkModeActive');
        _this.addClass('pushed');
        setTimeout(() => {
            _this.removeClass('pushed');
        }, 300);
        toggleDarkMode(_body, _isActive);
    });
}

function initGlobalActions() {
    $('.banner-close').on('click', function () {
        $(this).closest('.banner').remove();
    });
    $('.banner-text').on('mouseover', function () {
        $(this).find('.banner-tooltip').addClass('show')
    })
    $('.banner-text').on('mouseleave', function () {
        $(this).find('.banner-tooltip').removeClass('show')
    })

    $('.show-password').on('click', function() {
        let input_password = $(this).siblings('input');
        let type = input_password.attr('type') === 'text' ? 'password' : 'text';
        input_password.attr('type', type);
    });
}

// function launchCountDown() {
//     let countDownElement = $('#countdown');
//     var countDownDate = new Date("Dec 17, 2022 22:00:00").getTime();
//     var x = setInterval(function() {
//         countDown();
//     }, 1000);
//     function countDown() {
//         var now = new Date().getTime();
//         var distance = countDownDate - now;
//         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
//         days = days > 9 ? days : '0'+days
//         var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//         hours = hours > 9 ? hours : '0'+hours
//         var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//         minutes = minutes > 9 ? minutes : '0'+minutes
//         var seconds = Math.floor((distance % (1000 * 60)) / 1000);
//         seconds = seconds > 9 ? seconds : '0'+seconds
//         countDownElement.html(` / ${days}:${hours}:${minutes}:${seconds}`);
//         if (distance < 0) {
//             clearInterval(x);
//             countDownElement.remove();
//             $('.confetti').append(`<lottie-player src="https://assets8.lottiefiles.com/packages/lf20_rovf9gzu.json"
//                     class="lottie-files" background="transparent"  speed="1" loop autoplay style="width: 100%">
//                 <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>`)
//         }
//     }
//     countDown();
// }

export { drawBrandText, initParticlesJS, initDarkMode, initGlobalActions }
